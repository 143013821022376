import React from 'react'

import PageTitle from '../components/Typography/PageTitle'

function Blank() {
  return (
    <>
      <PageTitle>Blank</PageTitle>
    </>
  )
}

export default Blank
