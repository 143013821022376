import { NavLink } from "react-router-dom";
import Icon from "../components/Icon";
import React, { useState } from "react";
import PageTitle from "../components/Typography/PageTitle";
import { HomeIcon, IoBuildSharp, FaDownload } from "../icons";
import { Card, CardBody, Label, Input, Button } from "@windmill/react-ui";
import useAuth from '../useAuth';
import { useSnackbar } from 'notistack';
import { RiLogoutCircleFill } from "react-icons/ri";

const FormTitle = ({ children }) => {
  return (
    <h2 className="mb-3 text-sm font-semibold text-gray-600 dark:text-gray-300">
      {children}
    </h2>
  );
};

const AddProduct = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { user, token, logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const handleLogout = () => {
    enqueueSnackbar('Você saiu!', { variant: 'error' });
    logout();
  };

  if (!user) {
    return <p style={{color: "#fff"}}>Carregando informações do usuário...</p>;
  }

  const totalDevicesCount = user && user.devices 
    ? user.devices.length 
    : 0;
    
  const permittedDevicesCount = user && user.devices 
    ? user.devices.filter(device => device.permissions && device.permissions.includes("true")).length 
    : 0;

  const offlineDevicesCount = user && user.devices 
    ? user.devices.filter(device => !device.online).length 
    : 0;

  const imageStyle = {
    borderRadius: '50%',
    width: '100px',
    height: '100px',
    objectFit: 'cover'
  };

  return (
    <div>
      <PageTitle>Perfil</PageTitle>
      <div className="flex text-gray-800 dark:text-gray-300">
        <div className="flex items-center text-purple-600">
          <Icon className="w-5 h-5" aria-hidden="true" icon={HomeIcon} />
          <NavLink exact to="/app/dashboard" className="mx-2">
            Dashboard
          </NavLink>
        </div>
        {">"}
        <p className="mx-2">Perfil</p>
      </div>

      <div className="w-full mt-8 grid gap-4 grid-col md:grid-cols-3 ">
        <Card className="row-span-2 md:col-span-2">
          <CardBody>
            <center>
              <img 
                src="https://wallpapercave.com/wp/wp2634859.jpg" 
                style={imageStyle}
                alt="Profile"
              />
            </center>
            <FormTitle>Usuário</FormTitle>
            <Label>
              <Input className="mb-4" value={user.name} readOnly />
            </Label>

            <FormTitle>ID</FormTitle>
            <Label>
              <Input className="mb-4" value={user.id} readOnly />
            </Label>

            <FormTitle>PIX</FormTitle>
            <Label>
              <Input className="mb-4" value={user.pix} readOnly />
            </Label>

            <FormTitle>Aparelhos Infectados (KL)</FormTitle>
            <Label>
              <Input className="mb-4" value={totalDevicesCount} readOnly />
            </Label>

            <Button size="small" onClick={handleLogout} iconLeft={RiLogoutCircleFill}>
              Sair
            </Button>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddProduct;
