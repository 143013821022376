import { useState, useEffect } from 'react';
import jsCookie from 'js-cookie';
import { useSnackbar } from 'notistack';

const useAuth = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedToken = jsCookie.get('token');
    if (!storedToken) {
      setLoading(false);
      return;
    }

    const fetchUserData = async () => {
      try {
        const res = await fetch('https://thc.bnbempresta.fun/user', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + storedToken,
          },
        });
        const data = await res.json();
        if (res.ok && data.status === 'success') {
          setToken(storedToken);
          setUser(data.data);
        } else {
          handleLogout(data.message || 'Erro ao conectar com o servidor!');
        }
      } catch (error) {
        handleLogout('Erro ao conectar com o servidor!');
      } finally {
        setLoading(false);
      }
    };

    const handleLogout = (message) => {
      enqueueSnackbar(message, { variant: 'error' });
      jsCookie.remove('token');
      setToken(null);
      setUser(null);
    };

    fetchUserData();
    const intervalId = setInterval(fetchUserData, 1000);
    return () => clearInterval(intervalId);
  }, [enqueueSnackbar]);

  const login = (newToken, newUser) => {
    jsCookie.set('token', newToken, { expires: 7 });
    setToken(newToken);
    setUser(newUser);
  };

  const logout = () => {
    jsCookie.remove('token');
    setToken(null);
    setUser(null);
  };

  return { loading, token, user, login, logout };
};

export default useAuth;
