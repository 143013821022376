import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";
import { SnackbarProvider } from 'notistack';
import ProtectedRoute from './ProtectedRoute';
import useAuth from './useAuth';
import AddProduct from "./pages/AddProduct";
import ProductsAll from "./pages/ProductsAll";
import Profile from "./pages/Profile";
import Blank from "./pages/Blank";

const Layout = lazy(() => import("./containers/Layout"));
const Orders = lazy(() => import("./pages/Orders"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const SingleProduct = lazy(() => import("./pages/SingleProduct"));  // Import the SingleProduct component
const ManageAPI = lazy(() => import("./pages/ManageApiToken"));
const ConsultaCPF = lazy(() => import("./pages/ConsultaCPF"));
const BuyAPI = lazy(() => import("./pages/BuyApi"));
const BuyCHKS = lazy(() => import("./pages/BuyChks"));
const ManageHwid = lazy(() => import("./pages/ManageHwid"));
const Settings = lazy(() => import("./pages/Settings"));
const Unauthorized = lazy(() => import("./pages/Unauthorized"));

const Loading = () => (
  <div className="preloader">
    <div className="spinner"></div>
  </div>
);

function App() {
  const { loading, token, login } = useAuth();

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <SnackbarProvider maxSnack={3}>
        <AccessibleNavigationAnnouncer />
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/login">
              <Login setUser={login} />
            </Route>
            <Route path="/create-account" component={CreateAccount} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <ProtectedRoute path="/app" isAuthenticated={!!token} component={Layout} />
            <ProtectedRoute path="/app/dashboard" isAuthenticated={!!token} component={Orders} />
            <ProtectedRoute path="/app/infectados" isAuthenticated={!!token} component={Orders} />
            <ProtectedRoute path="/app/build" isAuthenticated={!!token} component={AddProduct} />
            <ProtectedRoute path="/app/create" isAuthenticated={!!token} component={ProductsAll} />
            <ProtectedRoute path="/app/manage-api" isAuthenticated={!!token} component={ManageAPI} />
            <ProtectedRoute path="/app/consulta-cpf" isAuthenticated={!!token} component={ConsultaCPF} />
            <ProtectedRoute path="/app/manage-profile" isAuthenticated={!!token} component={Profile} />
            <ProtectedRoute path="/app/manage-hwid" isAuthenticated={!!token} component={ManageHwid} />
            <ProtectedRoute path="/app/buy-api" isAuthenticated={!!token} component={BuyAPI} />
            <ProtectedRoute path="/app/buy-chks" isAuthenticated={!!token} component={BuyCHKS} />
            <ProtectedRoute path="/app/settings" isAuthenticated={!!token} component={Settings} />
            <ProtectedRoute path="/app/blank" isAuthenticated={!!token} component={Blank} />
            <ProtectedRoute path="/app/device/:id" isAuthenticated={!!token} component={SingleProduct} />
            <Route path="/unauthorized" component={Unauthorized} />
            <Redirect exact from="/app" to="/app/dashboard" />
            <Redirect exact from="/" to="/login" />
          </Switch>
        </Suspense>
      </SnackbarProvider>
    </Router>
  );
}

export default App;
